import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    ProductType,
} from 'app/types/enums'
import {
    AccountTableEntity, AccountStatus,
} from './AccountManagement.types'

const getAccountFields = (
    getAllowedValues,
    exists,
): DTOCardFormField<keyof AccountTableEntity>[] => {
    return [{
        title: 'General Information',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'company',
                        labelKey: 'Accounts.accountCompany',
                        componentName: 'CompanyDataSelector',
                        required: true,
                        disabled: exists,
                    },
                    {
                        name: 'accountStatus',
                        labelKey: 'Accounts.accountStatus',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(AccountStatus),
                        useDropdown: true,
                        required: true,
                    },
                    {
                        name: 'annualPotentialVolume',
                        labelKey: 'Accounts.annualPotentialVolume',
                        componentName: 'InputSingleline',
                        required: true,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'accountOwner',
                        labelKey: 'Accounts.accountOwner',
                        componentName: 'UserObjectSelector',
                        required: true,
                    },
                    {
                        name: 'approvedProducts',
                        labelKey: 'Accounts.approvedProducts',
                        componentName: 'EnumChipSelector',
                        allowedValues: getAllowedValues(ProductType),
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'collaborators',
                        labelKey: 'Accounts.accountCollaborators',
                        componentName: 'MultipleUserSelector',
                    },
                    {
                        name: 'createdOn',
                        labelKey: 'Accounts.createdOn',
                        componentName: 'InputSingleline',
                        disabled: true,
                    },
                    {
                        name: 'createdBy',
                        labelKey: 'Accounts.createdBy',
                        componentName: 'InputSingleline',
                        disabled: true,
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'hasMLA',
                        labelKey: 'Accounts.hasMLA',
                        componentName: 'Checkbox',
                    },
                    {
                        name: 'hasAcquisitionPlan',
                        labelKey: 'Accounts.hasAcquisitionPlan',
                        componentName: 'Checkbox',
                    },
                    {
                        name: 'supportingDocuments',
                        labelKey: 'Accounts.supportingDocuments',
                        componentName: 'AttachmentsObjectSelector',
                    },
                ],
            },
        ],

    }]
}

export default getAccountFields
