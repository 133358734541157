import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'

export const emailNameCompanyOrLocationConverter = ((items: Contact[]) => {
    return items.map((contact) => {
        return {
            ...contact,
            label: [
                contact.email,
                contact.contactName,
                contact.isProspect ? contact.companyCompanyName : contact.locationLocationName,
            ].join(', '),
        }
    })
})

export default emailNameCompanyOrLocationConverter
