import React, {
    useEffect,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import StatusHandler from 'app/shared-components/StatusHandler'
import useGetPrebookingByParams from './hooks/useGetPrebookingByParams'
import ClosePrebookingTable from './ClosePrebookingTable'
import ClosePrebookingForm from './ClosePrebookingForm'

const ClosePrebooking = ({
    customerReference,
    mawb,
    setSelectedPrebooking,
    selected,
}: Readonly<{
    customerReference: string,
    mawb: string,
    selected: number,
    setSelectedPrebooking: React.Dispatch<React.SetStateAction<number>>
}>) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetPrebookingByParams({
        mawb,
    })

    useEffect(() => {
        if (isDataReady && !isEmpty(data) && !isEqual(data?.id, selected)) {
            setSelectedPrebooking(data?.id)
        }
    }, [
        isDataReady,
        data,
        selected,
        setSelectedPrebooking,
    ])

    if ((isEmpty(customerReference) && isEmpty(mawb)) || (isDataReady && isEmpty(data))) {
        return (
            <ClosePrebookingTable
                selectPrebooking={setSelectedPrebooking}
                selected={selected}
            />
        )
    }

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ClosePrebookingForm data={data} />
        </StatusHandler>
    )
}

export default ClosePrebooking
